import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Dropdown,
  Menu,
  MenuItem,
  MenuButton,
  LinearProgress,
  Typography,
  ListItemContent,
  ListItemDecorator,
  ListItemButton,
  ListItem,
  List,
  Input,
  IconButton,
  Modal,
  ModalClose,
  Sheet,
  Divider,
  Box,
  Avatar,
  GlobalStyles,
} from "@mui/joy";

import { Link } from "react-router-dom";

import { listItemButtonClasses } from "@mui/joy/ListItemButton";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import NewspaperIcon from "@mui/icons-material/Newspaper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import DeleteForever from "@mui/icons-material/DeleteForever";
import MoreVert from "@mui/icons-material/MoreVert";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { closeSidebar } from "./utils";

function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export function f_date(d) {
  return d
    .split("-")
    .map((x, idx) => x[0].toUpperCase() + x.slice(1) + (idx === 1 ? "," : ""))
    .join(" ");
}

export const Sidebar = () => {
  const [days, setDays] = useState([]);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const res = await (await fetch("/spox-list")).json();

      setDays(
        Object.keys(res).sort(
          (x, y) => new Date(y).getTime() - new Date(x).getTime(),
        ),
      );
    };
    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      const res = await (await fetch("/voice-list")).json();

      setVoices(Object.keys(res).sort());
    };
    fn();
  }, []);

  return (
    <>
      <Sheet
        className="Sidebar"
        sx={{
          position: { xs: "fixed", md: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            md: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": "220px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "240px",
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: "var(--SideNavigation-slideIn)",
            backgroundColor: "var(--joy-palette-background-backdrop)",
            transition: "opacity 0.4s",
            transform: {
              xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)",
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <IconButton
            variant="soft"
            color="primary"
            size="sm"
            component={Link}
            to="/"
          >
            <NewspaperIcon />
          </IconButton>
          <Typography level="title-lg">
            <b>
              <i>THE NEWS IS TRAPPED IN THIS ROOM</i>
            </b>
          </Typography>
        </Box>
        {true ? (
          ""
        ) : (
          <Input
            size="sm"
            startDecorator={<SearchRoundedIcon />}
            placeholder="Search"
            disabled={true}
          />
        )}
        <Box
          sx={{
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
            }}
          >
            <ListItem>
              <ListItemButton component={Link} to="/">
                <HomeRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">renders</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <CalendarMonthIcon />
                    <ListItemContent>
                      <Typography level="title-sm">edits</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0 }}>
                  {days.map((d) => (
                    <ListItem>
                      <ListItemButton component={Link} to={`/edit/${d}`}>
                        {f_date(d)}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Toggler>
            </ListItem>

            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <GroupRoundedIcon />
                    <ListItemContent>
                      <Typography level="title-sm">voices</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0 }}>
                  {voices.map((d) => (
                    <ListItem>
                      <ListItemButton disabled={true}>
                        {" "}
                        {/*component={Link} to={`/voice/${d}`}>*/}
                        {d}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Toggler>
            </ListItem>
          </List>

          <List
            size="sm"
            sx={{
              mt: "auto",
              flexGrow: 0,
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
              "--List-gap": "8px",
              mb: 2,
            }}
          >
            {false && (
              <ListItem>
                <ListItemButton component={Link} to="/documentation">
                  <SupportRoundedIcon />
                  docs
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
        <Divider />
        {false && (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar variant="outlined" size="sm" />
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Typography level="title-sm">whoami</Typography>
              <Typography level="body-xs">...but really!</Typography>
            </Box>
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              onClick={() => {
                const fn = async () => {
                  const res = await fetch("/api/sign-out");
                  await res.json();
                  console.log(res);
                  window.location.reload();
                };
                fn();
              }}
            >
              <LogoutRoundedIcon />
            </IconButton>
          </Box>
        )}
      </Sheet>
    </>
  );
};

export default Sidebar;
