import React, { useState, useMemo, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import CssBaseline from "@mui/joy/CssBaseline";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dropdown,
  Input,
  IconButton,
  Textarea,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  MenuButton,
  Modal,
  ModalClose,
  Sheet,
  Typography,
  Tooltip,
} from "@mui/joy";

import { CssVarsProvider, extendTheme } from "@mui/joy/styles";

import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";

import { f_date } from "./Sidebar";

import "@fontsource/ibm-plex-sans";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const theme = extendTheme({
  fontFamily: {
    display: "IBM Plex Sans", // applies to `h1`–`h4`
    body: "IBM Plex Sans", // applies to `title-*` and `body-*`
  },
  colorSchemes: {
    light: {
      palette: {},
    },
    dark: {
      palette: {},
    },
  },
});

const TitleAndAction = ({ title, Action }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2" component="h1">
          {title}
        </Typography>
        {Action}
      </Box>
    </>
  );
};

const f_num = (n) => {
  let s = "";

  const ns = "" + n;

  for (let i = 0; i < ns.length; i++) {
    s = ns[ns.length - i - 1] + s;
    if ((i + 1) % 3 === 0 && i < ns.length - 1) {
      s = "," + s;
    }
  }

  return s;
};

const QuestionBackground = ({ uid, spec }) => {
  if (!uid) {
    return "";
  }
  return (
    <Box
      sx={{
        position: "fixed",
        top: "20px",
        right: "20px",
        pointerEvents: "none",
        opacity: 0.5,
        textAlign: "right",
        width: "calc(100vw-140px)",
      }}
    >
      {spec
        .filter((x) => x.uid === uid)
        .map(({ questions, summaries, duration }) => (
          <>
            {summaries.map((x) => (
              <Typography>{x}</Typography>
            ))}
          </>
        ))}
    </Box>
  );
};

const Home = () => {
  const [ren, setRen] = useState([]);
  const [hov, setHov] = useState();

  useEffect(() => {
    const fn = async () => {
      const res = await (await fetch("/render-list")).json();
      res.reverse();
      setRen(res);
    };
    fn();
  }, []);

  let tot_duration = 0;
  let tot_questions = 0;
  for (const { questions, duration } of ren) {
    tot_questions += questions;
    tot_duration += duration;
  }

  return (
    <>
      <Button disabled={!ren.length} sx={{ m: 4 }} component={Link} to={`/all`}>
        {!ren.length
          ? "Loading..."
          : `${f_num(tot_questions)} Questions (${f_time(tot_duration)})`}
      </Button>

      <QuestionBackground uid={hov} spec={ren} />

      <List>
        {ren.map(({ uid, duration, questions, summaries }) => (
          <ListItem
            onMouseOver={() => setHov(uid)}
            onMouseOut={() => setHov()}
            component={Link}
            to={`/day/${uid}`}
          >
            {f_date(uid)}
          </ListItem>
        ))}
      </List>
    </>
  );
};

const RenderButton = ({ spoxId, sen, checked }) => {
  const [renderOpen, setRenderOpen] = useState(false);

  return (
    <>
      <Modal
        sx={{
          zIndex: 15000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={renderOpen}
        onClose={() => setRenderOpen(false)}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 800,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            render
          </Typography>

          <LinearProgress />
          <Typography>Note: no completion indication yet...</Typography>
        </Sheet>
      </Modal>

      <Button
        color="primary"
        startDecorator={<CloudUploadIcon />}
        size="sm"
        onClick={() => {
          setRenderOpen(true);

          // start rendering!
          const fn = async () => {
            // Generate seq

            const seq = [];
            let lastIdx = -2;
            for (const [idx, dist] of Object.entries(sen)) {
              if (!checked[idx]) {
                continue;
              }
              if (lastIdx + 1 === Number(idx)) {
                // Merge
                seq[seq.length - 1].end = sen[idx].end;
              } else {
                // New!
                seq.push({
                  id: spoxId,
                  start: sen[idx].start,
                  end: sen[idx].end,
                });
              }
            }

            const rres = await fetch("/render", {
              method: "post",
              body: JSON.stringify({
                uid: `v1-${spoxId}`,
                spec: {
                  size: [640, 360],
                  seq: seq,
                },
              }),
            });
            const rout = await rres.json();
          };
          fn();
        }}
      >
        render
      </Button>
    </>
  );
};

function zp(n, len) {
  let s = "" + n;
  while (s.length < len) {
    s = "0" + s;
  }
  return s;
}
function f_time(t) {
  const h = Math.floor(t / (60 * 60));
  const m = Math.floor((t - h * 60 * 60) / 60);
  const s = Math.round(t % 60);

  return `${zp(h, 2)}:${zp(m, 2)}:${zp(s, 2)}`;
}

const Spox = () => {
  const { spoxId } = useParams();
  const [sen, setSen] = useState();
  const [checked, setChecked] = useState({});

  const duration = useMemo(() => {
    let tot = 0;
    for (let i = 0; i < (sen || []).length; i++) {
      if (checked[i]) {
        tot += sen[i].end - sen[i].start;
      }
    }
    return tot;
  }, [sen, checked]);

  useEffect(() => {
    setSen();

    const fn = async () => {
      const res = await fetch("/sentences", {
        method: "post",
        body: JSON.stringify({ uid: spoxId }),
      });
      const out = await res.json();
      setSen(out);

      const sel = {};
      for (let i = 0; i < out.length; i++) {
        sel[i] = out[i].checked;
      }
      setChecked(sel);
    };

    fn();
  }, [spoxId]);

  const Tx = (
    <Container>
      <Typography>{f_time(duration)} selected</Typography>

      {(sen || []).map(({ wdlist, start, run_idx, rep_idx }, idx) => (
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Dropdown>
            <MenuButton slots={{ root: IconButton }}>
              <MoreVertIcon />
            </MenuButton>
            <Menu placement="bottom-start">
              <MenuItem
                onClick={() => {
                  console.log("Remove...");
                  const fn = async () => {
                    const res = await fetch("/sentences", {
                      method: "post",
                      body: JSON.stringify({
                        uid: spoxId,
                        idx,
                        task: "similar-voice",
                      }),
                    });
                    const matches = await res.json();
                    const checks = { ...checked };

                    matches.forEach((m, idx) => {
                      if (m) {
                        checks[idx] = false;
                      }
                    });

                    setChecked(checks);
                  };

                  fn();
                }}
              >
                remove voice
              </MenuItem>
              <MenuItem
                color="warning"
                onClick={() => {
                  console.log("Label...");

                  const name = prompt("name this voice");

                  const fn = async (name) => {
                    const res = await fetch("/sentences", {
                      method: "post",
                      body: JSON.stringify({
                        uid: spoxId,
                        idx,
                        name,
                        task: "ban-voice",
                      }),
                    });
                    const out = await res.json();
                    console.log("BANNED", out);
                  };

                  if (name) {
                    fn(name);
                  } else {
                    alert("no name provided - canceling");
                  }
                }}
              >
                ban voice
              </MenuItem>
              <MenuItem
                onClick={() => {
                  console.log("Label...");

                  const name = prompt("name this voice");

                  const fn = async (name) => {
                    const res = await fetch("/sentences", {
                      method: "post",
                      body: JSON.stringify({
                        uid: spoxId,
                        idx,
                        name,
                        task: "label-voice",
                      }),
                    });
                    const out = await res.json();
                    console.log("Labeled", out);
                  };

                  if (name) {
                    fn(name);
                  } else {
                    alert("no name provided - canceling");
                  }
                }}
              >
                name voice
              </MenuItem>
            </Menu>
          </Dropdown>
          <Checkbox
            checked={checked[idx]}
            onChange={(e) => {
              const obj = { ...checked };
              obj[idx] = e.target.checked;
              setChecked(obj);
            }}
            sx={{ flexGrow: 1 }}
            label={
              wdlist.map((x) => x.word).join("") +
              ` [Run ${run_idx} / Rep ${rep_idx}]`
            }
          />
        </Box>
      ))}
    </Container>
  );

  return (
    <>
      <TitleAndAction
        title={f_date(spoxId)}
        Action={<RenderButton spoxId={spoxId} sen={sen} checked={checked} />}
      />
      {!sen ? <i>Loading...</i> : Tx}
    </>
  );
};
const Render = () => {
  const [vid, setVid] = useState();
  const [info, setInfo] = useState();
  const ref = useRef();

  const { spoxId } = useParams();

  useEffect(() => {
    const fn = async () => {
      const vres = await fetch("/signed-video", {
        method: "post",
        body: JSON.stringify({ uid: spoxId }),
      });
      const vout = await vres.json();
      setVid(vout);

      const res = await fetch("/render-info", {
        method: "post",
        body: JSON.stringify({ uid: spoxId }),
      });
      const out = await res.json();

      let t = 0;
      for (const item of out.seq) {
        item.v_start = t;
        t += item.end - item.start;
      }

      setInfo(out);
    };

    setVid();
    setInfo();
    if (spoxId) {
      fn();
    }
  }, [spoxId]);

  return (
    <>
      {!vid ? (
        <i>Loading...</i>
      ) : (
        <video ref={ref} src={vid} controls width="100%" />
      )}

      <Box p={4}>
        {!info
          ? ""
          : info.seq.map(({ summary, raw_transcript, v_start }) => (
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  ref.current.currentTime = v_start;
                }}
                level={true ? "" : "h3"}
              >
                {summary}
              </Typography>
            ))}
      </Box>
    </>
  );
};

const Main = () => {
  return (
    <>
      <Typography sx={{ mb: 2 }}>
        <Link to="/">A Bunch of Questions With No Answers</Link>
      </Typography>

      <Outlet />
    </>
  );
};

const Docs = () => {
  return <h2>docs</h2>;
};

const Voice = () => {
  return <h2>voice</h2>;
};

const AllQuestions = ({ uid }) => {
  const [vid, setVid] = useState();
  const [tx, setTx] = useState();

  useEffect(() => {
    const fn = async () => {
      const vres = await fetch("/signed-video", {
        method: "post",
        body: JSON.stringify({ uid }),
      });
      const vout = await vres.json();
      setVid(vout);

      const res = await fetch("/render-tx", {
        method: "post",
        body: JSON.stringify({ uid }),
      });
      const r_tx = await res.json();
      setTx(r_tx);
    };

    fn();
  }, []);

  if (!tx) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Typography>{uid.split("-")[1]}</Typography>
      <video src={vid} controls width="100%" />
      {tx.seq.map(({ id, text, start, end }) => (
        <Typography>
          {id} ({f_time(start)} - {f_time(end)}): {text}
        </Typography>
      ))}
    </>
  );
};

const All = () => {
  const [vid, setVid] = useState();
  const [ren, setRen] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const res = await (await fetch("/render-list")).json();
      setRen(res);
    };
    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      const vres = await fetch("/signed-video", {
        method: "post",
        body: JSON.stringify({ all: true }),
      });
      const vout = await vres.json();
      setVid(vout);
    };

    fn();
  }, []);

  return (
    <>
      {!vid ? <i>Loading...</i> : <video src={vid} controls width="100%" />}

      <Box p={4}>
        {ren.map(({ summaries }) =>
          summaries.map((x) => <Typography>{x}</Typography>),
        )}
      </Box>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "", element: <Home /> },
      { path: "edit/:spoxId", element: <Spox /> },
      { path: "day/:spoxId", element: <Render /> },
      { path: "voice/:spoxId", element: <Voice /> },
      { path: "all", element: <All /> },
      { path: "all-questions", element: <AllQuestions uid="render-all" /> },
      { path: "time", element: <AllQuestions uid="render-time" /> },
      { path: "said", element: <AllQuestions uid="render-said" /> },
      { path: "documentation", element: <Docs /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssVarsProvider
      defaultMode="system"
      theme={theme}
      modeStorageKey="diary-system-mode"
      disableNestedContext
    >
      <CssBaseline />
      <RouterProvider router={router} />
    </CssVarsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
